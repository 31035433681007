import { useGetProject } from "@/hooks/api";
import { useAuth, useUser } from "@clerk/clerk-react";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  MantineColorScheme,
  MantineTheme,
  Paper,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconSend } from "@tabler/icons-react";
import MarkdownIt from "markdown-it";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

interface Context {
  metadata: {
    page: number;
    source: string;
  };
  page_content: string;
  type: string;
}

export interface IStep {
  id: string;
  role: string;
  type: "user_message" | "bot_message" | "image" | "loader";
  content: string;
  createdAt: string;
  context?: Context[];
}

const INITIAL_QUESTION_OPTIONS: {
  question: string;
  type: "pv" | "bess" | null;
}[] = [
  { question: "What is the MWdc capacity of the project?", type: "pv" },
  { question: "Which PV module is used in the project?", type: "pv" },
  {
    question:
      "Our last inverter inspection was on 4/3/2024, when is the next one due?",
    type: null,
  },
  {
    question: "What is the emergency shutdown procedure of the project?",
    type: null,
  },
  {
    question: "What was the average project meter power between 2-3pm today?",
    type: null,
  },
  {
    question: "Plot yesterday's project active power meter output",
    type: null,
  },
  {
    question: "What type of battery is used in the project?",
    type: "bess",
  },
];

const url = import.meta.env.PROD
  ? "wss://chat.proximal.energy/ws"
  : "ws://127.0.0.1:8001/ws";

export function ChatCard({
  messages,
  setMessages,
  firstQuestionAsked,
  setFirstQuestionAsked,
}: {
  messages: IStep[];
  setMessages: React.Dispatch<React.SetStateAction<IStep[]>>;
  firstQuestionAsked: boolean;
  setFirstQuestionAsked: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { projectId } = useParams();
  const { user } = useUser();
  const [inputValue, setInputValue] = useState("");
  const [isLoading] = useState(false);
  const viewportRef = useRef<HTMLDivElement>(null);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  const ws = useRef<WebSocket | null>(null);
  const { getToken } = useAuth();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
    queryOptions: {
      enabled: !!projectId,
    },
  });

  const markdown = useMemo(() => new MarkdownIt(), []);

  const renderMarkdown = (content: string) => {
    const renderedContent = markdown.render(content);
    const styledContent = renderedContent
      .replace(/<p>/g, '<p style="margin: 0; padding: 0;">')
      .replace(
        /<code>/g,
        '<code style="color: white; font-size: 0.875em; background-color: #333333; padding: 2px 4px; border-radius: 4px;">'
      )
      .replace(
        /<pre>/g,
        '<pre style="color: white; font-size: 0.875em; background-color: #333333; padding: 10px; border-radius: 4px; overflow-x: auto;">'
      )
      .replace(
        // Add style to images to set max width:
        /<img/g,
        '<img style="max-width: 580px; height: auto;"'
      );
    return { __html: styledContent };
  };

  useEffect(() => {
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      console.log("Connection opened");
    };

    ws.current.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      // console.log(messageData)

      setMessages((msgs) => {
        const lastMessage = msgs[msgs.length - 1];
        if (messageData.type === "text") {
          if (lastMessage && lastMessage.role === "bot") {
            const updatedLastMessage = {
              ...lastMessage,
              content: lastMessage.content + messageData.content,
            };
            return [...msgs.slice(0, -1), updatedLastMessage];
          } else {
            const botMessage: IStep = {
              id: uuidv4(),
              role: "bot",
              type: "bot_message",
              content: messageData.content,
              createdAt: new Date().toISOString(),
            };
            return [...msgs, botMessage];
          }
        } else if (messageData.type === "image") {
          console.log(messageData);
          const updatedLastMessage = {
            ...lastMessage,
            content:
              lastMessage.content +
              `![Output Image](data:image/png;base64,${messageData.content})`,
          };
          return [...msgs.slice(0, -1), updatedLastMessage];
        } else if (messageData.type === "loader") {
          const loaderMessage: IStep = {
            id: uuidv4(),
            role: "bot",
            type: "loader",
            content: `${messageData.content}`,
            createdAt: new Date().toISOString(),
          };
          return [...msgs, loaderMessage];
        }
        return msgs; // Ensure msgs is always returned
      });
    };

    ws.current.onclose = () => {
      console.log("Connection closed");
    };

    ws.current.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    return () => {
      ws.current?.close();
    };
  }, []);

  useEffect(() => {
    if (viewportRef.current) {
      viewportRef.current.scrollTo({
        top: viewportRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  if (!user?.id) return null;
  if (!projectId) return null;

  const handleQuestionSelect = (question: string) => {
    handleSendMessage(question);
  };

  if (!project.data) return null;

  const InitialQuestionButtons = () => {
    return (
      <Stack align="flex-start">
        {INITIAL_QUESTION_OPTIONS.filter((question) => {
          if (project.data.project_type_id === 1) {
            return question.type !== "bess";
          } else if (project.data.project_type_id === 2) {
            return question.type !== "pv";
          } else {
            return true;
          }
        }).map((question, index) => (
          <Button
            key={index}
            onClick={() => handleQuestionSelect(question.question)}
            variant="outline"
            size="xs"
          >
            {question.question}
          </Button>
        ))}
      </Stack>
    );
  };

  const handleSendMessage = async (message: string) => {
    const token = await getToken({ template: "default" });

    if (message) {
      setFirstQuestionAsked(true);
      const userMessage: IStep = {
        id: uuidv4(),
        role: "user",
        type: "user_message",
        content: message,
        createdAt: new Date().toISOString(),
      };

      setMessages((msgs) => [...msgs, userMessage]);
      setInputValue("");

      const payload = {
        message,
        chat_history: messages.map((msg) => msg.content),
        project_id: projectId,
        user_id: user.id,
        token: token,
        session_id: projectId,
      };

      ws.current?.send(JSON.stringify(payload));
    }
  };

  const ContextButtons = ({
    context,
    colorScheme,
    theme,
  }: {
    context: Context[];
    colorScheme: MantineColorScheme;
    theme: MantineTheme;
  }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const getFilename = (source: string) => {
      const parts = source.split("/");
      return parts[parts.length - 1];
    };

    const toggleActiveIndex = (index: number) => {
      if (activeIndex === index) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };

    const referenceDetailStyle = {
      color:
        colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
      fontWeight: "bold",
    };

    return (
      <>
        <Group pt="sm" justify="space-between">
          {context.map((_, index) => (
            <Button
              key={index}
              size="xs"
              variant={activeIndex === index ? "filled" : "outline"}
              onClick={() => toggleActiveIndex(index)}
              style={{
                backgroundColor:
                  activeIndex === index
                    ? colorScheme === "dark"
                      ? theme.colors.dark[5]
                      : theme.colors.gray[2]
                    : "transparent",
                color:
                  colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.gray[7],
                border: `1px solid ${
                  colorScheme === "dark"
                    ? theme.colors.dark[4]
                    : theme.colors.gray[4]
                }`,
              }}
            >
              {`Ref. ${index + 1}`}
            </Button>
          ))}
        </Group>
        {activeIndex !== null && (
          <Box mt="xs">
            <Text size="xs" style={referenceDetailStyle}>
              {`Page ${context[activeIndex].metadata.page} of ${getFilename(
                context[activeIndex].metadata.source
              )}`}
            </Text>
            <Text
              size="xs"
              c={
                colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors.gray[7]
              }
            >
              {context[activeIndex].page_content}
            </Text>
          </Box>
        )}
      </>
    );
  };

  const renderMessage = (message: IStep) => {
    const isUserMessage = message.type === "user_message";

    const contextElements =
      message.context && message.context.length > 0 ? (
        <ContextButtons
          context={message.context}
          colorScheme={colorScheme}
          theme={theme}
        />
      ) : null;

    return (
      <Box
        key={message.id}
        w="100%"
        style={{
          display: "flex",
          justifyContent: isUserMessage ? "flex-end" : "flex-start",
        }}
      >
        <Paper
          p="sm"
          maw="70%"
          color={theme.primaryColor}
          style={{
            backgroundColor: isUserMessage
              ? colorScheme === "dark"
                ? theme.colors[theme.primaryColor][9]
                : theme.colors[theme.primaryColor][1]
              : colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[1],
          }}
        >
          <Text
            size="sm"
            dangerouslySetInnerHTML={renderMarkdown(message.content)}
          />
          {contextElements}
        </Paper>
      </Box>
    );
  };

  return (
    <Stack p="md" h={400} w={700} justify="space-between">
      {!firstQuestionAsked ? (
        <InitialQuestionButtons />
      ) : (
        <ScrollArea flex={1} viewportRef={viewportRef}>
          <Stack w="100%" gap="sm">
            {messages.map(renderMessage)}
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Loader size="sm" />
              </div>
            )}
          </Stack>
        </ScrollArea>
      )}
      <Textarea
        placeholder="Ask anything about this asset..."
        autosize
        value={inputValue}
        onChange={(event) => setInputValue(event.currentTarget.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage(inputValue);
          }
        }}
        minRows={1}
        maxRows={4}
        rightSection={
          <ActionIcon
            disabled={inputValue.length === 0}
            size="sm"
            variant="transparent"
          >
            <IconSend
              onClick={() => handleSendMessage(inputValue)}
              width={"100%"}
            />
          </ActionIcon>
        }
        autoFocus
      />
    </Stack>
  );
}
