import { useUpdateFailureMode } from "@/hooks/api";
import * as types from "@/hooks/types";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  Timeline,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEdit } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const DeviceEventsTimeline = ({
  events,
  failureModes,
  projectId,
  selectedEvent,
}: {
  events: types.Event[];
  failureModes: types.FailureMode[];
  projectId: string;
  selectedEvent: types.Event;
}) => {
  const [opened, { close, open }] = useDisclosure(false);

  const mutation = useUpdateFailureMode();
  events = [...events].sort(
    (a, b) => dayjs(b.time_start).unix() - dayjs(a.time_start).unix()
  );

  let timelineLength = 2;
  if (events[0].time_end) {
    timelineLength = 1;
  }

  const updateRootCause = (failureModeId: number) => {
    mutation.mutate({
      project_id: projectId,
      event_id: selectedEvent.event_id,
      failure_mode_id: failureModeId,
    });
  };

  const EditRootModal = ({
    opened,
    failureModes,
  }: {
    opened: boolean;
    onClose: () => void;
    failureModes: types.FailureMode[];
  }) => {
    const [selectedFailureMode, setSelectedFailureMode] = useState<number>(
      selectedEvent.failure_mode_id ?? -1
    );
    const navigate = useNavigate();
    return (
      <Modal opened={opened} onClose={close} title="Edit Root Cause">
        <Stack>
          <Select
            data={[
              ...failureModes.map((fm) => ({
                value: fm.failure_mode_id.toString(),
                label: fm.name_long,
              })),
            ]}
            placeholder="Select a root cause"
            value={selectedFailureMode.toString()}
            onChange={(value) => setSelectedFailureMode(Number(value))}
          />
          <Group grow>
            <Button onClick={close}>Cancel</Button>
            <Button
              onClick={() => {
                updateRootCause(selectedFailureMode);
                close();
                navigate(0);
              }}
            >
              Save
            </Button>
          </Group>
        </Stack>
      </Modal>
    );
  };

  return (
    <ScrollArea scrollbars="y" p="xs">
      <ScrollArea.Autosize mah={650} mih={650}>
        <Box>
          <EditRootModal
            opened={opened}
            onClose={close}
            failureModes={failureModes}
          />
          <Timeline active={events.length - timelineLength} reverseActive>
            {events.map((event, index) => (
              <Timeline.Item key={index}>
                <Link
                  to={`/projects/${projectId}/events/event/?eventId=${event.event_id}`}
                  style={{ color: "inherit" }}
                >
                  <Text>
                    {event.time_end
                      ? `${dayjs(event.time_start).format(
                          "MM/DD/YYYY"
                        )} - ${dayjs(event.time_end).format("MM/DD/YYYY")}`
                      : dayjs(event.time_start).format("MM/DD/YYYY")}
                  </Text>
                </Link>
                <Group>
                  <Text size="sm">
                    Root cause:{" "}
                    {failureModes.find(
                      (fm) => fm.failure_mode_id === event.failure_mode_id
                    )?.name_long || "Unknown"}
                  </Text>
                  {selectedEvent.event_id === event.event_id && (
                    <ActionIcon onClick={open} size="xs">
                      <IconEdit />
                    </ActionIcon>
                  )}
                </Group>
              </Timeline.Item>
            ))}
          </Timeline>
        </Box>
      </ScrollArea.Autosize>
    </ScrollArea>
  );
};

export default DeviceEventsTimeline;
