import { useGetProject } from "@/hooks/api";
import {
  BackgroundImage,
  Overlay,
  Stack,
  Text,
  useComputedColorScheme,
} from "@mantine/core";

const ProjectPicture = ({
  project,
  showText,
}: {
  project: ReturnType<typeof useGetProject>["data"];
  showText: boolean;
}) => {
  const computedColorScheme = useComputedColorScheme();

  return (
    <BackgroundImage
      src={
        project?.image_url ??
        "https://www.renewableenergyworld.com/wp-content/uploads/2021/09/muscle-shoals-1200x675.png"
      }
      radius="xs"
      h={150}
      pos="relative"
    >
      <Overlay
        color={computedColorScheme === "dark" ? "#242424" : "#fff"}
        backgroundOpacity={computedColorScheme === "dark" ? 0.5 : 0.7}
        zIndex={1}
      />
      <Stack p="md" gap={0} h="100%" justify="end">
        {showText && (
          <>
            <Text size="xl" fw={800} lh={1} style={{ zIndex: 2 }}>
              {project?.name_long}
            </Text>
            <Text size="xl" fw={600} fs="italic" lh={1} style={{ zIndex: 2 }}>
              {project?.poi} MW {project?.project_type?.name_long}
            </Text>
          </>
        )}
      </Stack>
    </BackgroundImage>
  );
};

export default ProjectPicture;
