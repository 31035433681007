import CustomCard from "@/components/CustomCard";
import EventGISCard from "@/components/EventGISCard";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetEventLosses,
  useGetEvents,
  useGetFailureModes,
  useGetProject,
  useGetTimeSeries,
} from "@/hooks/api";
import * as types from "@/hooks/types";
import { ActionIcon, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconCaretDown, IconCaretUp } from "@tabler/icons-react";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DeviceEventsTimeline from "./DeviceEventsTimeline";
import EventStepper from "./EventStepper";
import ScheduledMaintenanceCalendar from "./ScheduledMaintenanceCalendar";

const getNextDayEndingWith1or15 = (date: Dayjs) => {
  let nextDate = dayjs(date).add(1, "day");
  while (!(nextDate.date() === 1 || nextDate.date() === 15)) {
    nextDate = nextDate.add(1, "day");
  }
  return nextDate;
};

const EventPage = () => {
  const { projectId } = useParams();
  const [expandCalculation, setExpandCalculation] = useState(false);

  const { data: project, isLoading: isProjectLoading } = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });
  const useQuery = () => new URLSearchParams(useLocation().search);
  const eventId = parseInt(useQuery().get("eventId") || "-1");

  const { data: eventData, isLoading: isEventDataLoading } = useGetEvents({
    pathParams: { projectId: projectId || "-1" },
    queryParams: { event_ids: [eventId], open: false },
  });
  const event = eventData?.[0];

  const { data: eventsHistorical, isLoading: isEventsHistoricalLoading } =
    useGetEvents({
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        device_id: event?.device_id?.toString() || "-1",
        open: false,
      },
    });

  const { data: eventLosses, isLoading: isEventLossesLoading } =
    useGetEventLosses({
      pathParams: { projectId: projectId || "-1" },
      queryParams: { event_ids: [eventId] },
    });

  const { data: failureModes, isLoading: isFailureModesLoading } =
    useGetFailureModes({
      pathParams: { projectId: projectId || "-1" },
    });

  const sensor_type_dict = [
    { id: 2, name: "pv_pcs_ac_power" },
    { id: 3, name: "pv_pcs_module_ac_power" },
    { id: 5, name: "meter_active_power" },
    { id: 9, name: "pv_dc_combiner_current" },
  ];
  const sensor_type = sensor_type_dict.find(
    (d) => d.id === event?.device?.device_type_id
  )?.name;
  const { data: timeSeries, isLoading: isTimeSeriesLoading } = useGetTimeSeries(
    {
      pathParams: { projectId: projectId || "-1" },
      queryParams: {
        device_ids: [parseInt(event?.device_id.toString() || "-1")],
        sensor_type_name_shorts: [sensor_type ?? ""],
        start: dayjs(event?.time_start)
          .startOf("D")
          .format("YYYY-MM-DD 00:00:00"),
        end: dayjs(event?.time_start)
          .startOf("D")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
      },
    }
  );

  const isLoading =
    isProjectLoading ||
    isEventDataLoading ||
    isEventsHistoricalLoading ||
    isEventLossesLoading ||
    isFailureModesLoading;

  if (isLoading) return <PageLoader />;

  const deviceNameFull = event
    ? `${event.device_name_full}`
    : "Device (unknown)";

  const dailyLoss = eventLosses?.length
    ? eventLosses
        .filter((event) => event.event_loss_type_id === 2)
        .reduce((acc, event) => acc + event.loss, 0) /
      eventLosses.filter((event) => event.event_loss_type_id === 2).length
    : 0;

  const today = dayjs();
  const nextMaintenance = getNextDayEndingWith1or15(today);
  const daysUntilMaintenance = nextMaintenance.diff(today, "days");
  const noActionLoss = daysUntilMaintenance * dailyLoss;

  const flightCost = 500;
  const lodgingCost = 1000;
  const actionLoss = flightCost + lodgingCost + 2 * dailyLoss;
  const actionBenefit = noActionLoss - actionLoss;
  const offTime = dayjs(event?.time_start)
    .tz(project?.time_zone)
    .format("YYYY-MM-DD HH:mm:ssZ");

  return (
    <Stack h="100%" w="100%" p="sm">
      <Group align="flex-start" justify="center">
        <Stack flex={4} h="100%">
          <Stack align="center">
            <Title order={1}>
              {project
                ? `${project.name_long} - ${deviceNameFull} Failure`
                : "Loading..."}
            </Title>
            {dailyLoss > 0 ? (
              <Text>${dailyLoss.toFixed(2)} revenue loss per day</Text>
            ) : (
              <Text>Unknown revenue loss per day</Text>
            )}
            {event && <EventStepper event={event} />}
            <Paper p="md" withBorder>
              <>
                <Text fw={700}>ProxiBot Recommendation</Text>
                <Group justify="space-between">
                  {event?.time_end == null ? (
                    actionBenefit > 0 ? (
                      <Text>
                        Create Ticket: Repair {deviceNameFull} immediately.
                      </Text>
                    ) : (
                      <Text>
                        Create Ticket: Repair {deviceNameFull} on next scheduled
                        maintenance.
                      </Text>
                    )
                  ) : (
                    <Text>No issue at this time.</Text>
                  )}
                  <ActionIcon
                    onClick={() => setExpandCalculation(!expandCalculation)}
                    disabled={!dailyLoss || dailyLoss === 0}
                  >
                    {expandCalculation ? <IconCaretUp /> : <IconCaretDown />}
                  </ActionIcon>
                </Group>
                {dailyLoss > 0 && expandCalculation && (
                  <>
                    <br />
                    <Text fw={700}>Calculation</Text>
                    <Text>
                      Next scheduled maintenance:{" "}
                      {nextMaintenance.format("MMMM D")} ({daysUntilMaintenance}{" "}
                      days) -{">"} ${noActionLoss.toFixed(2)} loss
                    </Text>
                    <Text>
                      Unscheduled visit: ASAP (2 days) -{">"} ${flightCost}{" "}
                      flight + ${lodgingCost} lodging + $
                      {(2 * dailyLoss).toFixed(2)} loss = $
                      {actionLoss.toFixed(2)}
                    </Text>
                    {actionBenefit > 0 ? (
                      <Text ta="center" fw={700} fz="lg" c="green">
                        ${actionBenefit.toFixed(2)} Benefit
                      </Text>
                    ) : (
                      <Text ta="center" fw={700} fz="lg">
                        No mobilization benefit.
                      </Text>
                    )}
                  </>
                )}
              </>
            </Paper>
            {dailyLoss > 0 && expandCalculation && (
              <>
                <Title order={3}>Scheduled Maintenance Visits</Title>
                <Paper withBorder>
                  <ScheduledMaintenanceCalendar />
                </Paper>
              </>
            )}
          </Stack>
          <Group grow p="xs" h="100%">
            <CustomCard
              title={"Event Location"}
              style={{ height: "100%" }}
              fill
            >
              <EventGISCard deviceId={event?.device_id.toString() || "-1"} />
            </CustomCard>
            <CustomCard
              title={
                event?.device?.device_type_id === 9
                  ? "Device Current"
                  : "Device Power"
              }
              style={{ height: "100%" }}
            >
              <PlotlyPlot
                data={timeSeries?.map((d) => ({
                  x: d.x,
                  y: d.y,
                  name: d.name,
                  hoverlabel: {
                    namelength: -1,
                  },
                }))}
                layout={{
                  yaxis: {
                    title:
                      event?.device?.device_type_id === 9
                        ? "Current (A)"
                        : "Power (MW)",
                    side: "left",
                  },
                  shapes: [
                    {
                      type: "line",
                      x0: offTime,
                      x1: offTime,
                      y0: 0,
                      y1: 1,
                      xref: "x",
                      yref: "paper",
                      line: {
                        color: "black",
                        width: 2,
                        dash: "dot",
                      },
                    },
                  ],
                }}
                isLoading={isTimeSeriesLoading}
              />
            </CustomCard>
          </Group>
        </Stack>
        <Stack flex={1} h="100%">
          <CustomCard title="Timeline" style={{ height: "100%" }}>
            {eventsHistorical && (
              <DeviceEventsTimeline
                events={eventsHistorical}
                failureModes={failureModes || []}
                projectId={projectId || "-1"}
                selectedEvent={event ?? ({} as types.Event)}
              />
            )}
          </CustomCard>
        </Stack>
      </Group>
    </Stack>
  );
};

export default EventPage;
