import CustomCard from "@/components/CustomCard";
import { PageLoader } from "@/components/Loading";
import DeviceSunburst from "@/components/plots/DeviceSunburst";
import LossWaterfall from "@/components/plots/LossWaterfall";
import POIMeter from "@/components/plots/POIMeter";
import PowerPlantController from "@/components/plots/PowerPlantController";
import { useGetProject } from "@/hooks/api";
import {
  ActionIcon,
  Group,
  Popover,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { PlotType } from "plotly.js";
import { useState } from "react";
import { useParams } from "react-router-dom";

const SystemPerformance = () => {
  const { projectId } = useParams();
  const [showLevel, setShowLevel] = useState("device_type");
  const [sunburstDepth, setSunburstDepth] = useState<string>("3");
  const [sunburstStyle, setSunburstStyle] = useState<PlotType>("sunburst");

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  if (project.isLoading) return <PageLoader />;

  return (
    <Stack p="sm" h="100%">
      <Title order={1}>System Performance</Title>
      <Group flex={1} w="100%">
        <CustomCard title="POI Meter" style={{ height: "100%", flex: 1 }}>
          <POIMeter />
        </CustomCard>
        <CustomCard
          title="System Device Health"
          style={{ height: "100%", flex: 1 }}
          headerChildren={
            <Popover>
              <Popover.Target>
                <ActionIcon variant="default">
                  <IconSettings />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Select
                  data={["2", "3", "4"]}
                  label={"Plot Depth"}
                  value={sunburstDepth}
                  onChange={(value) => setSunburstDepth(value ?? "3")}
                />
                <Text>Plot Style:</Text>
                <SegmentedControl
                  data={[
                    { label: "Circular", value: "sunburst" },
                    { label: "Rectangular", value: "icicle" },
                  ]}
                  value={sunburstStyle}
                  onChange={(value) => setSunburstStyle(value as PlotType)}
                />
              </Popover.Dropdown>
            </Popover>
          }
        >
          <DeviceSunburst
            depth={parseInt(sunburstDepth)}
            style={sunburstStyle}
          />
        </CustomCard>
      </Group>
      <Group flex={1} w="100%">
        <CustomCard
          title="Power Plant Controller"
          style={{ height: "100%", flex: 1 }}
        >
          <PowerPlantController />
        </CustomCard>
        {project.data?.has_event_integration && (
          <CustomCard
            headerChildren={
              <SegmentedControl
                data={[
                  { label: "Component", value: "device_type" },
                  { label: "Root Cause", value: "failure_mode_id" },
                ]}
                value={showLevel}
                onChange={setShowLevel}
              />
            }
            title="Loss Waterfall"
            info="The loss waterfall charts highlight energy losses throughout the system, illustrating how each factor contributes to the overall loss. These losses can be broken down by component type or by root cause. In the component view, losses are grouped by the type of equipment affected, while in the root cause view, they are grouped by the failure modes causing the losses. The expected production, calculated based on system specifications and environmental conditions, is compared to the actual production. Any difference between the two, after accounting for known losses, is considered unaccounted loss, highlighting areas that may need further investigation."
            style={{ height: "100%", flex: 1 }}
          >
            <LossWaterfall level={showLevel} />
          </CustomCard>
        )}
      </Group>
    </Stack>
  );
};

export default SystemPerformance;
