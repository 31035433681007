import CustomCard from "@/components/CustomCard";
import { useValidateDateRange } from "@/components/datepicker/utils";
import { AdvancedDatePicker } from "@/components/GIS";
import { PageLoader } from "@/components/Loading";
import PlotlyPlot from "@/components/plots/PlotlyPlot";
import {
  useGetKPIProjectTemplate,
  useGetKPIType,
  useGetProject,
} from "@/hooks/api";
import { KPIProjectTemplate } from "@/hooks/types";
import { Box, Button, Group, Stack, Title } from "@mantine/core";
import { Layout } from "plotly.js";
import { useParams } from "react-router-dom";

const Page = ({
  kpiTypeId,
  cardTitle,
  plotLayout,
}: {
  kpiTypeId: number;
  cardTitle: string;
  plotLayout?: Partial<Layout>;
}) => {
  const { projectId } = useParams();

  const project = useGetProject({
    pathParams: { projectId: projectId || "-1" },
  });

  const { start, end } = useValidateDateRange({});

  let startQuery: string | undefined = undefined;
  let endQuery: string | undefined = undefined;

  if (project.data) {
    if (start) {
      startQuery = start.tz(project.data.time_zone, true).toISOString();
    }
    if (end) {
      endQuery = end.tz(project.data.time_zone, true).toISOString();
    }
  }

  const kpiType = useGetKPIType({
    pathParams: { kpiTypeId },
  });

  // Query KPI data
  const data = useGetKPIProjectTemplate({
    pathParams: {
      projectId: projectId || "-1",
      kpiTypeNameShort: kpiType.data?.name_short.replace(/_/g, "-") || "",
    },
    queryParams: {
      start: startQuery,
      end: endQuery,
    },
    queryOptions: {
      enabled: !!project.data && !!start && !!end && !!kpiType.data,
      staleTime: Infinity,
    },
  });

  const pageIsLoading = project.isLoading || kpiType.isLoading;

  return (
    <Box h="100%" w="100%">
      {pageIsLoading && <PageLoader />}
      <Stack p="md" h="100%" display={pageIsLoading ? "none" : "flex"}>
        {kpiType.data && <Title order={1}>{kpiType.data.name_long}</Title>}
        <Group justify="space-between">
          <AdvancedDatePicker
            includeClearButton={false}
            defaultRange="past-week"
          />
          <Button
            disabled={data.isLoading}
            onClick={() =>
              data.data &&
              download({
                name: kpiType.data?.name_long || "kpi_data",
                data: data.data,
              })
            }
          >
            Download
          </Button>
        </Group>
        <CustomCard title={cardTitle} style={{ flex: 1 }}>
          <PlotlyPlot
            data={
              data.data && [
                {
                  x: data.data.data.timestamps,
                  y: data.data.data.values,
                  type: "scatter",
                  mode: "lines",
                },
              ]
            }
            layout={plotLayout}
            isLoading={data.isLoading}
          />
        </CustomCard>
      </Stack>
    </Box>
  );
};

const download = ({
  name,
  data,
}: {
  name: string;
  data: KPIProjectTemplate;
}) => {
  if (!data || !data.data) return;

  // Create CSV content
  const csvContent = [
    ["Timestamp", name].join(","),
    ...data.data.timestamps.map((timestamp, i) =>
      [timestamp, data.data.values[i]].join(",")
    ),
  ].join("\n");

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${name}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default Page;
